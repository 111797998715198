import { Tenant } from 'new/types/tenants';
import pngLogo from 'new/assets/logos/VHQ-ondark.png';
import pngLogoSmall from 'new/assets/logos/VHQ-ondark.png';
import pngLogoOnLight from 'new/assets/logos/VHQ-onlight.png';
import { ReactComponent as SvgLogo } from 'new/assets/logos/VHQ-ondark.svg';
import { ReactComponent as SvgLogoSmall } from 'new/assets/logos/VHQ-ondark-small.svg';
import { ReactComponent as SvgLogoOnLight } from 'new/assets/logos/VHQ-onlight.svg';

const vhq: Tenant = {
  id: 'vhq',
  name: 'VisionHQ',
  shortName: 'VHQ',
  nameSimple: 'Vision',
  supportEmail: 'support@vision-hq.com',
  successEmail: 'success@vision-hq.com',
  features: {
    setupBaa: false,
    gpp: false,
    leaderboardWidgetType: 'lightGreen',
    pms: false,
    csvSetup: false,
    payoutIssues: false,
    canDisableFamilyDiscounts: true,
  },
  constants: {
    launchRewards: {
      prize30: 25,
      prize60: 50,
      prize90: 100,
    },
    seasonOfGivingPrize: '$100, $50, and $25',
    marketingTemplates:
      'https://visionsource-uploads.s3.us-east-2.amazonaws.com/VHQ+-+Email+%26+Text+Blasts+to+Patients+(Template).docx',
  },
  elevio: {
    cancellations: '105',
    trackPayments: '25',
    pricing: '106',
  },
  logos: {
    pngLogo,
    pngLogoOnLight,
    pngLogoSmall,
    SvgLogo,
    SvgLogoSmall,
    SvgLogoOnLight,
  },
};

export default vhq;
